* {
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.toast.success {
    background-color: #20c96b !important;
}
.toast.warning {
    background-color: #ff910c !important;
}
.toast.error {
    background-color: #ff4e4e !important;
}
